import {
  CheckCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  Drawer,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Select,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Cities from "app/constants/cities.json";
import {
  ACCOUNT_STATUS,
  PROPERTY_TYPES_BHK_FILTERS,
  PROPRERTY_STATUS_FILTER_MAP,
} from "app/constants/global.constants";
import BrokerDetails from "app/scenes/brokers/components/BorkerDetails";
import InviteBroker from "app/scenes/brokers/components/BrokerList/InviteBroker";
import { apiDelete, apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  API_ENDPOINT_PROJECT_DELETE,
  API_ENDPOINT_PROJECT_INVESTORS_LIST,
  API_ENDPOINT_PROJECT_LIST,
  API_ENDPOINT_PROPERTY_INVENTORY_LIST,
  API_ENDPOINT_PROPERTY_MANAGE_LIST,
  API_ENDPOINT_PROPERTY_UPDATE_STATUS,
  API_ENDPOINT_PROPERTY_UPDATE_UNIT_STATUS,
} from "../../property.constants";
import InvestorDetail from "./components/InvestorDetail";

import "./ProjectList.scss";
import Loader from "app/shared/Loader";
import { colorPicker } from "app/utils/helpers/color.helper";
import { API_ENDPOINT_DASHBOARD_FETCH_PROPERTY_GIST } from "app/scenes/dashboard/dashboard.constants";
import Selector from "app/shared/Selector";
import SearchSelector from "app/shared/SearchSelector";

const PROPERTY_STATUS_MAP = [
  {
    value: "APPROVED",
    label: "Approved",
  },
  {
    value: "AVAILABLE",
    label: "Available",
  },
  {
    value: "DISABLED",
    label: "Disabeld",
  },
  {
    value: "UNDER_REVIEW",
    label: "Under Review",
  },
  {
    value: "Booked",
    label: "BOOKED",
  },
  {
    value: "SOLD",
    label: "Sold",
  },
];
const getDistinctInvestors = (data) => {
  let investors = [];
  data.forEach((investor) => {
    let findInvestor = investors.findIndex(
      (item) => item.investorId === investor.investorId
    );
    if (findInvestor !== -1) {
      investors[findInvestor].amount =
        parseInt(investors[findInvestor].amount) + parseInt(investor.amount);
    } else {
      investors.push(investor);
    }
  });
  return investors;
};
const renderInvestorChip = (data) => {
  let chips = [];
  getDistinctInvestors(data).forEach((element) => {
    chips.push(
      <Tag color="blue">{element?.investments?.user_profile?.fullname}</Tag>
    );
  });

  return chips;
};

const tableColumn = [
  {
    title: "Property ID",
    index: "property_code",
    renderTag: true,
    search: true,
  },
  {
    title: "Name",
    index: "name",
    ellipsis: true,
    search: true,
  },
  {
    title: "Type",
    index: "property_type.name",
    renderTag: true,
  },
  {
    title: "BHK",
    render: (data) => {
      console.log("table data:?", data);
      return data?.property_type?.name === "Flat" && !data?.bedroom
        ? "Studio"
        : data?.property_type?.name === "Flat" ||
          data?.property_type?.name === "House/Villa"
        ? data?.bedroom + "BHK"
        : "NA";
    },
    filters: PROPERTY_TYPES_BHK_FILTERS,
    onFilter: (value, record) =>
      value >= 6 ? record.bedroom >= value : record.bedroom === value,
  },
  {
    title: "Total Units",
    render: (data) => {
      if (
        data?.property_units_lists &&
        typeof data?.property_units_lists === "object"
      ) {
        return data?.property_units_lists.length;
      } else {
        return <Tag color="blue">{data?.unitNo || "NA"}</Tag>;
      }
    },
  },
  {
    title: "Project",
    index: "property_project.name",
    renderTag: true,
    search: true,
  },
  {
    title: "City",
    index: "property_project.property_project_address.city",
    ellipsis: true,
    search: true,
  },
  {
    title: "Location",
    index: "property_project.property_project_address.address_one",
    ellipsis: true,
    search: true,
  },
  {
    title: "Cost",
    index: "cost",
    amount: true,
  },

  // {
  //   title: "Created By",
  //   index: "user.user_profile.fullname",
  // },
];

const statusColumn = {
  title: "Status",
  index: "property_project.name",
  // filters: PROPERTY_STATUS_MAP,
  // onFilter: (value, record) => record.status.indexOf(value) === 0,

  handleAction: (propsData) => propsData.handleAction(propsData),
  customRender: (props) => {
    console.log("Data", props.status);
    return (
      <Select
        defaultValue={props.status}
        style={{
          width: 120,
          height: 26,
        }}
        onChange={(data) =>
          props.handleAction({
            newStatus: data,
            eventType: "UPDATE_STATUS",
            ...props,
          })
        }
        options={[
          {
            value: "BOOKED",
            label: "Booked",
          },
          {
            value: "SOLD",
            label: "Sold",
          },
          {
            value: "HOLD",
            label: "Hold",
          },
        ]}
      />
    );
  },
};

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },

    {
      event: "edit_detail",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f1c40f",
    },
  ],
};

const actionColumnObj2 = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "UPDATE_STATUS",
      icon: <CheckCircleOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#73AF00",
    },

    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },

    {
      event: "edit_detail",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f1c40f",
    },
  ],
};

const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

const manageListTypeMap = {
  1: "AVAILABLE",
  2: "BOOKED",
  3: "SOLD",
  4: "APPROVED",
  5: "UNDER_REVIEW",
  6: "ALL",
  7: "HOLD",
};
export default function InventoryManagement(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [statusUnitNo, setStatusUnitNo] = useState("");
  const [uniHoldDay, setUnitHoldDay] = useState("");
  const [validateUnit, setValidateUnit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(FeatureFlags);
  const [modal, contextHolder] = Modal.useModal();
  const [brokerId, setBrokerId] = useState("");

  const [propertyGist, setPropertyGist] = useState(null);

  useEffect(() => {
    fetchPropertyGist();
  }, [selectedProject, selectedCity]);
  const fetchPropertyGist = async () => {
    const response = await apiGet(
      `${API_ENDPOINT_DASHBOARD_FETCH_PROPERTY_GIST}?${
        selectedProject ? "projectId=" + selectedProject : ""
      }&${selectedCity ? "city=" + selectedCity : ""}`
    );
    if (response.status) {
      setPropertyGist(response.data);
    }
  };

  const holdPurchaseColumn = [
    {
      title: "Hold For",
      render: (data) => {
        return (
          <div
            onClick={() =>
              navigate("/leads/" + data.property_unit_sale_infos?.[0]?.lead?.id)
            }
          >
            {data.property_unit_sale_infos?.[0] && (
              <SearchOutlined
                style={{ fontSize: 12, color: "green", marginRight: 6 }}
              />
            )}
            {data.property_unit_sale_infos?.[0]?.lead?.first_name || ""}{" "}
            {data.property_unit_sale_infos?.[0]?.lead?.last_name || ""}
          </div>
        );
      },
    },
  ];

  const soldPurchaseColumn = [
    {
      title: "Sold To",
      render: (data) => {
        return (
          <div
            onClick={() =>
              navigate("/leads/" + data.property_unit_sale_infos?.[0]?.lead?.id)
            }
          >
            {data.property_unit_sale_infos?.[0] && (
              <SearchOutlined
                style={{ fontSize: 12, color: "green", marginRight: 6 }}
              />
            )}
            {data.property_unit_sale_infos?.[0]?.lead?.first_name || ""}{" "}
            {data.property_unit_sale_infos?.[0]?.lead?.last_name || ""}
          </div>
        );
      },
    },
  ];

  const bookedPurchaseColumn = [
    {
      title: "Booked For",
      render: (data) => {
        return (
          <div
            onClick={() =>
              navigate("/leads/" + data.property_unit_sale_infos?.[0]?.lead?.id)
            }
          >
            {data.property_unit_sale_infos?.[0] && (
              <SearchOutlined
                style={{ fontSize: 12, color: "green", marginRight: 6 }}
              />
            )}
            {data.property_unit_sale_infos?.[0]?.lead?.first_name || ""}{" "}
            {data.property_unit_sale_infos?.[0]?.lead?.last_name || ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchPropertyList();
  }, [activeTab, selectedProject, selectedCity]);
  const fetchPropertyList = async () => {
    setLoading(true);
    const response = await apiGet(
      `${API_ENDPOINT_PROPERTY_INVENTORY_LIST}${manageListTypeMap[activeTab]}&${
        selectedProject ? "projectId=" + selectedProject : ""
      }&${selectedCity ? "city=" + selectedCity : ""}`
    );
    if (response.status) {
      setProperties(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    // setShowDrawerFor(type);
    setShowDrawer(true);
  };
  const handleBulkUpdate = async () => {
    console.log("selectedRows", selectedRowKeys);

    try {
      const requests = [];

      for (let i = 0; i < selectedRowKeys.length; i++) {
        // Push each API call promise into the requests array
        requests.push(
          apiPut(
            parseURL(API_ENDPOINT_PROPERTY_UPDATE_STATUS, {
              propertyId: selectedRowKeys[i],
              statusID: activeTab === "5" ? "APPROVED" : "AVAILABLE",
            })
          )
        );
      }
      console.log("resultquee", requests);
      // Execute all API calls concurrently using Promise.all
      const results = await Promise.all(requests);
      fetchPropertyList();
      setSelectedRowKeys([]);
      setSelectedRows([]);
      // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleTableAction = (eData) => {
    let event = eData.id ? eData : eData.rowData;
    switch (eData.eventType) {
      case "edit_detail":
        navigate(
          "/property/project/unit/edit/" +
            event.propertyProjectId +
            "/" +
            event.id
        );
        return;
      case "view_detail":
        navigate("/property/" + event.id);
        return;
      case "UPDATE_STATUS":
        let verifyStatsus = ["HOLD", "SOLD", "BOOKED", "AVAILABLE"];
        let unitEnteredValue = "";
        let selectedBroker = "";
        let content = verifyStatsus.includes(event.newStatus) ? (
          <div style={{ margin: "10px 0" }}>
            <div>
              <Selector
                placeholder="Unit No"
                serviceType="STATIC"
                valueKey="unit_no"
                labelKey="unit_no"
                onChange={(value) => {
                  setStatusUnitNo(value);
                  unitEnteredValue = value;
                }}
                height={50}
                //   defaultValue={projectName}
                staticData={event?.property_units_lists || []}
                size="small"
              />
            </div>
            {event.newStatus === "HOLD" ? (
              <div style={{ marginTop: 12 }}>
                <Input
                  placeholder="Hold Day"
                  size="small"
                  onChange={(e) => setStatusUnitNo(e.target.value)}
                />
                <Typography variant="caption">
                  How many days you want to hold
                </Typography>
              </div>
            ) : null}

            {event.newStatus === "SOLD" ? (
              <div style={{ marginTop: 12 }}>
                {/* <Input
                  placeholder="Broker Id"
                  size="small"
                  onChange={(e) => setBrokerId(e.target.value)}
                /> */}
                <SearchSelector
                  onChange={(userINfo) => {
                    setBrokerId(userINfo);
                    selectedBroker = userINfo;
                  }}
                  valueKey={"userId"}
                  placeholder="Select Broker"
                />
              </div>
            ) : null}
          </div>
        ) : (
          `Are you sure want to ${
            activeTab === "5" ? "approve" : "mark available"
          } this property`
        );
        if (verifyStatsus.includes(event.newStatus)) setValidateUnit(true);

        modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content: content,
          okText: "Yes",
          cancelText: "No",
          onOk: () =>
            handleUpdatePropertyStatus(
              event.id,
              event.newStatus,
              event.status,
              unitEnteredValue,
              selectedBroker
            ),
        });
      default:
        return null;
    }
  };

  const handleDeleteProject = async (projectId) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROJECT_DELETE + projectId)
    );
    if (response.status) {
      fetchPropertyList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleUpdatePropertyStatus = async (
    propertyId,
    status,
    oldStatus,
    unitNoValue,
    brokerIdInfo = brokerId
  ) => {
    let findIndexForOld = PROPERTY_STATUS_MAP.findIndex(
      (item) => item.value === oldStatus
    );
    let findIndexNew = PROPERTY_STATUS_MAP.findIndex(
      (item) => item.value === status
    );

    const response = await apiPut(
      parseURL(API_ENDPOINT_PROPERTY_UPDATE_UNIT_STATUS, {
        propertyId: propertyId,
        statusID: status,
      }) + (unitNoValue ? "?unit_no=" + unitNoValue : ""),
      { brokerId: brokerIdInfo }
    );
    if (response.status) {
      fetchPropertyList();
      setStatusUnitNo("");
      fetchPropertyGist();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Looks like no unit is available ",
          type: 0,
        },
      });
    }
  };

  const handleCheckboxChange = (e, record) => {
    const dataId = record.id;
    const { checked } = e.target;
    console.log("event is:", dataId);

    const newSelectedRowKeys = checked
      ? [...selectedRowKeys, dataId]
      : selectedRowKeys.filter((rowKey) => rowKey !== dataId);

    const newSelectedRows = checked
      ? [...selectedRows, record]
      : selectedRows.filter((row) => row.id !== dataId);

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  const selectColumn = {
    title: "All",
    render: (data) => (
      <Checkbox
        onChange={(e) => handleCheckboxChange(e, data)}
        checked={selectedRowKeys.includes(data.id)}
      />
    ),
  };
  const items = [
    {
      key: "1",
      label: "Available",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[...tableColumn, statusColumn, actionColumnObj]}
          handleAction={handleTableAction}
          scroll={{ x: true }}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "2",
      label: "Booked",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          scroll={{ x: true }}
          isLoading={isLoading}
          column={[
            ...tableColumn,
            ...bookedPurchaseColumn,
            {
              title: "Action",
              width: 200,
              render: (data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Popconfirm
                      title={`Are you sure want to mark this unit as available`}
                      // description="Are you sure to delete this task?"
                      onConfirm={() =>
                        handleUpdatePropertyStatus(
                          data.id,
                          "AVAILABLE",
                          "BOOKED",
                          data.unitNo
                        )
                      }
                      onCancel={null}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tag color="red" style={{ cursor: "pointer" }}>
                        Mark Available
                      </Tag>{" "}
                    </Popconfirm>

                    <Popconfirm
                      title={`Are you sure want to mark this unit as Sold`}
                      // description="Are you sure to delete this task?"
                      onConfirm={() =>
                        handleUpdatePropertyStatus(
                          data.id,
                          "SOLD",
                          "BOOKED",
                          data.unitNo
                        )
                      }
                      onCancel={null}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tag color="green" style={{ cursor: "pointer" }}>
                        Mark Sold
                      </Tag>
                    </Popconfirm>
                  </div>
                );
              },
            },
            actionColumnObj2,
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "3",
      label: "Sold",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          scroll={{ x: true }}
          column={[
            ...tableColumn,
            ...soldPurchaseColumn,
            {
              title: "Action",
              width: 200,
              render: (data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Popconfirm
                      title={`Are you sure want to cancel this sell`}
                      // description="Are you sure to delete this task?"
                      onConfirm={() =>
                        handleUpdatePropertyStatus(
                          data.id,
                          "AVAILABLE",
                          "BOOKED",
                          data.unitNo
                        )
                      }
                      onCancel={null}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tag color="red" style={{ cursor: "pointer" }}>
                        Cancel Sell
                      </Tag>{" "}
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "7",
      label: "On Hold",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          scroll={{ x: true }}
          column={[
            ...tableColumn,
            ...holdPurchaseColumn,
            ...[
              {
                title: "Action",
                width: 220,
                render: (data) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Popconfirm
                        title={`Are you sure want to mark this unit as available`}
                        // description="Are you sure to delete this task?"
                        onConfirm={() =>
                          handleUpdatePropertyStatus(
                            data.id,
                            "AVAILABLE",
                            "HOLD",
                            data.unitNo
                          )
                        }
                        onCancel={null}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tag color="red" style={{ cursor: "pointer" }}>
                          Mark Available
                        </Tag>{" "}
                      </Popconfirm>
                      <Popconfirm
                        title={`Are you sure want to mark this unit as booked`}
                        // description="Are you sure to delete this task?"
                        onConfirm={() =>
                          handleUpdatePropertyStatus(
                            data.id,
                            "BOOKED",
                            "HOLD",
                            data.unitNo
                          )
                        }
                        onCancel={null}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tag color="green" style={{ cursor: "pointer" }}>
                          Mark Booked
                        </Tag>{" "}
                      </Popconfirm>
                    </div>
                  );
                },
              },
            ],
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Approved",
    //   children: isLoading ? (
    //     <Loader />
    //   ) : (
    //     <TableView
    //       data={properties}
    //       isLoading={isLoading}
    //       column={[selectColumn, ...tableColumn, actionColumnObj2]}
    //       handleAction={handleTableAction}
    //       hasAccess={hasAccess}
    //     />
    //   ),
    // },
    // {
    //   key: "5",
    //   label: "Pending for Approval",
    //   children: isLoading ? (
    //     <Loader />
    //   ) : (
    //     <TableView
    //       data={properties}
    //       isLoading={isLoading}
    //       column={[selectColumn, ...tableColumn, actionColumnObj2]}
    //       handleAction={handleTableAction}
    //       hasAccess={hasAccess}
    //     />
    //   ),
    // },
    {
      key: "6",
      label: "All",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          scroll={{ x: true }}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
  ];

  return (
    <div className="PromoterList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {/* {hasAccess("PROPERTY_LISTING_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => navigate("/property/create")}>
                Create Property
              </Button>
            </div>
          ) : null} */}
        </div>
      </div>

      <div style={{ margin: "10px 0", display: "flex", alignItems: "center" }}>
        <div style={{ width: 200 }}>
          <Selector
            placeholder="Project"
            serviceType="PROPERTY_PROJECTS"
            valueKey="id"
            labelKey="name"
            onChange={(value) => setSelectedProject(value)}
            height={50}
            allowClear
            //   defaultValue={projectName}
          />
        </div>

        <div style={{ width: 200, marginLeft: 24 }}>
          <SearchSelector
            height={50}
            placeholder="City"
            staticData={Cities}
            serviceType="STATIC"
            labelKey="label"
            valueKey="value"
            onChange={(value) => setSelectedCity(value)}
            clearOnParentValue={selectedCity}
          />
        </div>
        {selectedProject || selectedCity ? (
          <div style={{ width: 200, marginLeft: 24 }}>
            <Button
              onClick={() => {
                setSelectedProject("");
                setSelectedCity("");
              }}
              size="small"
            >
              Clear Filter
            </Button>
          </div>
        ) : null}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="TopContainer">
            <div className="WidgetCard">
              <div className="left">
                <Typography variant="h4" weight={600}>
                  {propertyGist?.sold}
                </Typography>
                <Typography>Unit has been sold</Typography>
              </div>
              <div className="right">
                <Progress
                  strokeLinecap="butt"
                  type="circle"
                  percent={(propertyGist?.sold / propertyGist?.total) * 100}
                  strokeWidth={18}
                  showInfo={false}
                  size={68}
                  strokeColor={colorPicker("green")}
                />
              </div>
            </div>

            <div className="WidgetCard">
              <div className="left">
                <Typography variant="h4" weight={600}>
                  {propertyGist?.booked}
                </Typography>
                <Typography>Unit has booked</Typography>
              </div>
              <div className="right">
                <Progress
                  strokeLinecap="butt"
                  type="circle"
                  percent={(propertyGist?.booked / propertyGist?.total) * 100}
                  strokeWidth={18}
                  showInfo={false}
                  size={68}
                  strokeColor={colorPicker("yellow")}
                />
              </div>
            </div>

            <div className="WidgetCard">
              <div className="left">
                <Typography variant="h4" weight={600}>
                  {propertyGist?.hold}
                </Typography>
                <Typography>Unit on hold</Typography>
              </div>
              <div className="right">
                <Progress
                  strokeLinecap="butt"
                  type="circle"
                  percent={(propertyGist?.hold / propertyGist?.total) * 100}
                  strokeWidth={18}
                  showInfo={false}
                  size={68}
                />
              </div>
            </div>

            <div className="WidgetCard">
              <div className="left">
                <Typography variant="h4" weight={600}>
                  {propertyGist?.to_sell}
                </Typography>
                <Typography>Unit Available</Typography>
              </div>
              <div className="right">
                <Progress
                  strokeLinecap="butt"
                  type="circle"
                  percent={(propertyGist?.to_sell / propertyGist?.total) * 100}
                  strokeWidth={18}
                  showInfo={false}
                  size={68}
                  strokeColor={colorPicker("red")}
                />
              </div>
            </div>
            <div className="WidgetCard">
              <div className="left">
                <Typography variant="h4" weight={600}>
                  {propertyGist?.total}
                </Typography>
                <Typography>Total Unit</Typography>
              </div>
              <div className="right">
                {/* <Progress
              strokeLinecap="butt"
              type="circle"
              percent={(propertyGist?.rejected / propertyGist?.total) * 100}
              strokeWidth={18}
              showInfo={false}
              size={68}
              strokeColor={colorPicker("red")}
            /> */}
              </div>
            </div>
          </div>

          <div id="tabBarContainer">
            {selectedRowKeys.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ width: 200 }}
                  background="black"
                  onClick={handleBulkUpdate}
                >
                  Bulk {activeTab === "5" ? "Approve" : "Mark Available"}
                </Button>
              </div>
            ) : null}
            <TabBar
              items={items}
              color="red"
              onChange={(tab) => setActiveTab(tab)}
              inActiveColor="gray_dark"
              activeTab={activeTab}
            />
          </div>
        </>
      )}

      {contextHolder}
    </div>
  );
}
